import React, { useContext, useEffect } from "react";
import cookies from "cookies-js";
import { graphql, useStaticQuery } from "gatsby";
import { cookiesWork } from "@src/utils/cookies";
import SEO from "../components/SEO";
import HeroSection from "@src/components/partials/dojo-inspo/HeroSection";
import ActivitiesSection from "@src/components/partials/dojo-inspo/ActivitiesSection";
import heroImage from "@src/assets/images/dojo-inspo/dojo-inspo-hero.svg";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import GotInspo from "@src/components/partials/dojo-inspo/GotInspo";
import { logEvent } from "@src/utils/logClient";
import { useLocation } from "@reach/router";
import { get } from "lodash";

const tagSortOrder = {
  featured: 0,
  new: 1,
};

const PageDojoInspo: React.FC = ({ pageContext }) => {
  const gqlData = useStaticQuery(graphql`
    {
      directus {
        activities: page_resources_hub_resources(limit: 200) {
          id
          slug
          preview_background_color
          tag_list {
            page_resources_hub_tag_id {
              id
              slug
            }
          }
          translations {
            languages_code {
              code
            }
            activity_preview {
              id
              filename_disk
            }
          }
        }
        page_resources_hub_tag {
          id
          slug
          page_resources_hub_resources {
            id
          }
        }
        page_resources_hub_content {
          footer_button_url
        }
      }
    }
  `);
  const { page_resources_hub_tag, activities, page_resources_hub_content } = gqlData.directus;

  const userLanguageOriginal = get(pageContext, ["localeStrings", "currentLocale"], "en-us").toLowerCase();
  const userLanguage = userLanguageOriginal === "en" ? "en-us" : userLanguageOriginal;
  const { translate: t } = useContext(TranslationContext);
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const locale = cookiesWork() ? cookies.get("dojoDesiredLanguage") || "" : "";

  const handleQueryParams = (slug: string) => {
    let newUrl = "";
    if (slug === "") {
      urlSearchParams.delete("tag");
      newUrl = `${location.origin}${location.pathname}`;
    } else {
      urlSearchParams.set("tag", slug);
      newUrl = `${location.origin}${location.pathname}?${urlSearchParams.toString()}`;
    }
    window && window.history.replaceState(null, "", newUrl);
  };

  useEffect(() => {
    logEvent({
      eventName: `web.external.activity_corner.load_homepage`,
      eventValue: location.href,
      metadata: {
        params,
        language: userLanguage,
      },
    });
  }, []);

  const tags = page_resources_hub_tag.filter(({ page_resources_hub_resources: activities }) => activities.length > 0);

  const heroSectionProps = {
    heroHeading: "directus.dojo_inspo.hero_heading",
    heroTagline: "directus.dojo_inspo.hero_tagline",
    heroImage,
  };

  const activitiesSectionProps = {
    handleQueryParams,
    activeTag: params.tag || "",
    tags: tags.sort((tagA, tagB) => {
      const orderA = tagSortOrder[tagA.slug] || 2;
      const orderB = tagSortOrder[tagB.slug] || 2;

      return orderA - orderB;
    }),

    language: userLanguage,
    activities: activities
      .map((activity) => {
        const { id, slug, preview_background_color: backgroundColor, translations, tag_list: tags } = activity;
        const { activity_preview: previewImage } = translations.find(
          (t) => t.languages_code.code.toLowerCase() === locale || t.languages_code.code.toLowerCase() === "en-us",
        );
        return {
          id,
          title: `directus.dojo_inspo_activities_${id}.title`,
          description: `directus.dojo_inspo_activities_${id}.description`,
          previewImage,
          slug,
          backgroundColor,
          tags: tags.map(({ page_resources_hub_tag_id: { id, slug } }) => ({
            id,
            slug,
          })),
        };
      })
      .sort((itemA, itemB) => {
        const getPriority = (tags) => {
          if (tags.map(({ slug }) => slug).includes("featured")) return 0;
          if (tags.map(({ slug }) => slug).includes("new")) return 1;
          return 2;
        };

        const priorityA = getPriority(itemA.tags);
        const priorityB = getPriority(itemB.tags);

        if (priorityA < priorityB) return -1;
        if (priorityA > priorityB) return 1;
        return 0;
      }),
  };

  const gotInspoProps = {
    footerHeading: "directus.dojo_inspo.footer_heading",
    footerTagline: "directus.dojo_inspo.footer_tagline",
    footerButtonLabel: "directus.dojo_inspo.footer_button_label",
    footerButtonURL: page_resources_hub_content.footer_button_url,
    darkBackground: true,
  };

  return (
    <>
      <SEO
        title="directus.dojo_inspo.share_copy_title"
        description="directus.dojo_inspo.share_copy_description"
        image="https://static.classdojo.com/uploads/d2cb4e96-3f8a-4936-bc03-73c7d530c749.png"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: t("directus.dojo_inspo.share_copy_title").toString(),
          description: t("directus.dojo_inspo.hero_tagline").toString(),
        }}
      />
      <HeroSection {...heroSectionProps} />
      <ActivitiesSection {...activitiesSectionProps} />
      <GotInspo {...gotInspoProps} />
    </>
  );
};

export default PageDojoInspo;
